<template>
  <div class="profile" :class="{skeleton:!state.loaded}">
    <div class="title font-md">
      <b>진행자 </b>
      <span>프로필</span>
    </div>
    <div class="content">
      <div class="info">
        <MemberPicture :memberSeq="modalParams.memberSeq" size="50"/>
        <div class="name font-sm">{{ modalParams.memberName }}</div>
        <div class="sub">
          <div class="phone font-sm" v-if="modalParams.phoneNum">{{ modalParams.phoneNum }}</div>
          <div class="email font-sm" v-if="modalParams.email">
            <a class="pointer" title="메일 주소 복사" @click="copyEmailAddress(modalParams.email)" v-if="$env.device === 'desktop'">{{ modalParams.email }}</a>
            <a :href="`mailto:${modalParams.email}`" v-else>{{ modalParams.email }}</a>
          </div>
          <a class="homepage font-sm color-anchor" :href="modalParams.homepage" target="_blank" rel="noopener noreferrer" v-if="modalParams.homepage">{{ modalParams.homepage }}</a>
        </div>
        <div class="intro font-sm" v-if="modalParams.memberIntro">{{ modalParams.memberIntro }}</div>
        <button class="btn btn-bordered-secondary" @click="openMessageModal()">
          <span>메시지</span>
          <span class="d-none d-lg-inline"> 발송</span>
        </button>
      </div>
      <div class="slider" :class="{instantiated: state.loaded && state.swiper}">
        <div class="subject">
          <span>진행한 프로젝트</span>
          <span class="count font-xs" v-if="state.loaded && state.projects && state.projects.length">{{ state.projects.length }}</span>
        </div>
        <div class="wrapper">
          <div class="swiper-container" ref="swiperRef">
            <template v-if="state.projects && state.projects.length">
              <div class="swiper-wrapper">
                <router-link :to="`/${state.projectType}/${state.projectType === 'reward' ? p.projectSeq : p.investSeq}`" class="swiper-slide" v-for="(p, idx) in state.projects" :key="idx">
                  <span class="img" :style="{backgroundImage:`url('${p.filePath + p.fileSaveName}')`}"></span>
                  <span class="font-sm">{{ p.projectName }}</span>
                </router-link>
              </div>
            </template>
            <div class="pt-30 pb-30" v-else>
              <NoData/>
            </div>
          </div>
          <div class="navigation next swiper-button-next"></div>
          <div class="navigation prev swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalProfile";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, NoData},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
      state.projectType = modalParams.projectType;

      let url = `/api/${state.projectType}/projects/${modalParams.projectSeq}/other-projects`;
      const args = {};

      if (state.projectType === "invest") {
        args.memberSeq = modalParams.memberSeq;
      } else if (state.projectType === "reward") {
        url += `?openId=${modalParams.memberSeq}`;
      }

      const setSwiper = () => {
        const count = window.innerWidth > 991 ? 4 : 2;
        state.swiper = false;

        if (state.projects.length > count) {
          state.swiper = true;

          nextTick(() => {
            swiper = new window.Swiper(swiperRef.value, {
              slidesPerView: count,
              spaceBetween: 15,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });
          });
        }
      };

      for (let i = 0; i < 4; i += 1) {
        state.projects.push({
          projectName: "Wait a moment"
        });
      }

      http.get(url, args).then(({data}) => {
        state.loaded = true;
        state.projects = data.body;
        swiper?.destroy();
        state.projects?.length && nextTick(setSwiper);
      });
    });

    const state = reactive({
      loaded: false,
      projectType: null,
      projects: [],
      swiper: false
    });

    let swiper;
    const swiperRef = ref();
    const modalParams = store.getters.modalParams(component);

    const copyEmailAddress = (mailAddress) => {
      lib.copyToClipboard(mailAddress.trim());
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const openMessageModal = () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      } else if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: modalParams.memberSeq,
            name: modalParams.memberName
          }]
        }
      });
    };

    return {component, state, swiper, swiperRef, modalParams, copyEmailAddress, openMessageModal};
  }
});
</script>

<style lang="scss" scoped>
.profile {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px25;
  }

  .info {
    background: $colorBackground;
    border: $px1 solid #eee;
    margin-bottom: $px35;
    position: relative;
    padding: $px20 $px120 $px20 $px72;
    min-height: $px84;

    > .img {
      margin-right: $px10;
      position: absolute;
      top: $px17;
      left: $px11;
      background-position: center;
    }

    > .name {
      font-weight: 500;
    }

    .sub {
      color: #666;
      padding-top: $px3
    }

    .intro {
      padding-top: $px15;
    }

    .btn {
      position: absolute;
      top: $px20;
      right: $px20;
      height: $formHeightLg;
      line-height: calc(#{$formHeightLg} - 2px);
      padding: 0 $px18;
      font-size: $px14;

      .img {
        width: $px15;
        height: $px15;
        vertical-align: middle;
        margin-right: $px8;
      }
    }
  }

  &::v-deep {
    .slider {
      .subject {
        margin-bottom: $px15;

        span {
          vertical-align: middle;
        }

        .count {
          background: $colorSecondary;
          color: #fff;
          height: $px17;
          padding: 0 $px4;
          display: inline-block;
          line-height: $px16;
          font-size: $px10 !important;
          border-radius: $px2;
          margin-top: $px2;
          margin-left: $px8;
        }
      }

      > .wrapper {
        padding: 0 $px40;
        margin: 0 $px-10;
        position: relative;

        .swiper-container {
          width: 100%;
          height: 100%;

          .swiper-wrapper {
            //justify-content: space-between;

            .swiper-slide {
              text-align: center;
              font-size: $px18;
              background: #fff;
              text-decoration: none;
              width: calc(100% / 4);
              padding: 0 $px10;

              .img {
                display: block;
                padding-top: $ratio43Percent;
                margin-bottom: $px10;
                border: $px1 solid #eee;
              }

              span {
                display: inline-block;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              &:hover {
                font-weight: 500;

                .img {
                  border-color: #ddd;
                }
              }
            }
          }

          .swiper-pagination {
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            width: auto;

            .swiper-pagination-bullet {
              margin: 0 $px3;

              &.swiper-pagination-bullet-active {
                background: #5c5c5c;
              }
            }
          }
        }

        .navigation {
          transform: none;
          top: 0;
          height: 100%;
          margin-top: 0;
          color: #000;
          width: $px30;

          &:after {
            font-size: $px24;
            margin-top: $px-30;
          }

          &.next {
            right: 0;
          }

          &.prev {
            left: 0;
          }
        }
      }
    }
  }

  .slider:not(.instantiated) {
    > .wrapper {
      padding: 0;

      .navigation {
        display: none;
      }
    }
  }

  .slider.instantiated {
    > .wrapper {
      margin: 0;
    }

    .swiper-container .swiper-wrapper .swiper-slide {
      padding: 0;
    }
  }

  &.skeleton {
    .slider > .wrapper .swiper-container .swiper-wrapper .swiper-slide {
      .img {
        @include skeleton;
      }

      span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .slider > .wrapper {
      padding: 0 $px30;

      .swiper-container .swiper-wrapper .swiper-slide {
        width: 50%;
      }
    }
  }

  @media(max-width: 767px) {
    .info {
      padding-top: $px15;
      padding-right: $px60;
      padding-bottom: $px15;

      .btn {
        font-size: $px12;
        top: 0;
        right: $px15;
        margin-top: $px15;
        width: $px50;
        height: $px35;
        line-height: $px35;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>